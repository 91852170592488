// npm install qz-tray sha ws --save
//npm install jsrsasign --save
//https://qz.io/wiki/2.0-raw-printing#escpos
// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
//import store from '@/store'
import Vue from 'vue'

class Index {
    constructor() {
        this._qz = require('qz-tray')
        this._qz.api.setPromiseType(function promise(resolver) {
            return new Promise(resolver)
        })
        var createHash = require('sha.js')
        this._qz.api.setSha256Type(function (data) {
            return createHash('sha256').update(data).digest('hex')
        })
        var r = require('jsrsasign')
        this._qz.security.setCertificatePromise(function (resolve, reject) {
            resolve('-----BEGIN CERTIFICATE-----\n' +
                'MIID9DCCAtygAwIBAgIJAIQt39/6bUFKMA0GCSqGSIb3DQEBCwUAMIGOMQswCQYDVQQGEwJQRTEUMBIGA1UECAwLTGEgTGliZXJ0YWQxETAPBgNVBAcMCFRydWppbGxvMQ4wDAYDVQQKDAVEcmFpZjENMAsGA1UECwwEV29zdDERMA8GA1UEAwwIZHJhaWYucGUxJDAiBgkqhkiG9w0BCQEWFWdlcnNvbmhveWxlQGdtYWlsLmNvbTAeFw0xODAzMTQwNjMzMjRaFw00OTA5MDYwNjMzMjRaMIGOMQswCQYDVQQGEwJQRTEUMBIGA1UECAwLTGEgTGliZXJ0YWQxETAPBgNVBAcMCFRydWppbGxvMQ4wDAYDVQQKDAVEcmFpZjENMAsGA1UECwwEV29zdDERMA8GA1UEAwwIZHJhaWYucGUxJDAiBgkqhkiG9w0BCQEWFWdlcnNvbmhveWxlQGdtYWlsLmNvbTCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAOp+qQGZp50cJCZHPJSTbZAIFCOTjv1RDkJoLQKBCOpOX3atcPd/pP6SNuMpMxdhIRRTXek1snZxdKZBPLs+dIkR0Wh6N9QNyReQdgD5hAcZIs6G3uPZB7JgE/sD7Pswz3LOIN8VXGkNhta9WyKlYM9nPcO/IhGGE/iFY1w7Vx7nJv2HG/xY2eZWCp9n/KGVXVtubqsdpAKumhFaVBdKE3Z0h0AcEJPlqcSf6Ee3ey4C8pkgT1pCZKfP4BekmKoWSIwvQKKsBu0Hfdp9Es0jK7IuSEV2BbTh75SsQ64MTbAoHR1IbnWnjbjvRSbiWqAxn2cslN/PNJNu31yZFyZl5tECAwEAAaNTMFEwHQYDVR0OBBYEFCfYl5LKD/uEpbo3O4GjXzFoRIu5MB8GA1UdIwQYMBaAFCfYl5LKD/uEpbo3O4GjXzFoRIu5MA8GA1UdEwEB/wQFMAMBAf8wDQYJKoZIhvcNAQELBQADggEBAIzlJiP/vry2wUa/i2ksqa8Tj8OtJlOF+6FISnhxARaMZEyHf+y8AJ4F5gF0+UtHf5P5IOTfKGT2wOhdJVpsENjQbh/jpOTRiVmV+q/dgB2hi7aiVLFHJPzfLKBOtUVpCBhlwLcAfc23ivxey24adMU8BEanYFCkshT4+u/FJpxq99crq9HwOUcVGMxjCaHZg1ULz5VzDPhOLBY33YVXZrhaBDYk+rGTSOo0v1iQZSidyJVz7l4TCcqu3D9eL1R2Ps04W2cYP9KlFg7Pod7Tg5FQnIYIlVgfRWPVk6s4D85NJm6bht4Foe8uG0Nw9qfTdMFTv1hzDX6O8OTQgxOROFA=\n' +
                '-----END CERTIFICATE-----\n')
        })
        var privateKey = '-----BEGIN PRIVATE KEY-----\n' +
            'MIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDqfqkBmaedHCQmRzyUk22QCBQjk479UQ5CaC0CgQjqTl92rXD3f6T+kjbjKTMXYSEUU13pNbJ2cXSmQTy7PnSJEdFoejfUDckXkHYA+YQHGSLOht7j2QeyYBP7A+z7MM9yziDfFVxpDYbWvVsipWDPZz3DvyIRhhP4hWNcO1ce5yb9hxv8WNnmVgqfZ/yhlV1bbm6rHaQCrpoRWlQXShN2dIdAHBCT5anEn+hHt3suAvKZIE9aQmSnz+AXpJiqFkiML0CirAbtB33afRLNIyuyLkhFdgW04e+UrEOuDE2wKB0dSG51p42470Um4lqgMZ9nLJTfzzSTbt9cmRcmZebRAgMBAAECggEBAMF8mX9UhdVBLyLWjMobi7/YqqVgUA/9HcL1qo3yYqTS3jpSim/txvq3MsrlZ97WqjDXSRnw0LyiiRnVooLt2cJwDVYj4oT6iv8CmBvdxm8E8o9vAeMHZS5t0I7CgnwRnlqgcscgHQwhuSzfOZ+wzdzU10X5I+dJtpjNl1q3K/+2LzJGUOotBQn2uBtgHH9gsvxSyjz2w7JbMi/xoB8CzGFiemyULwqHn/kNql0kNlQi8LhxjPLxS56TNBHQ2s05uAGg/yfQwmso3577OkTzvTZCT5zRDBbaOSuSvTdpRfFo8gPPbyfg9A30WlNbr2cF51u//SVPeLIWjv7ohxpY2DECgYEA+ZzuC1HBgzwQggQsuOUqkJufDhQk1BjrAs6fO2pB7kvBuFzWji2PIvtrmMT55VcEkPsfcOkjImpncFnXvCo8oKJSHFbb+AMgNX4zepQVqRGzz3FlHiIZ4l/1op3nMgFDbrRGNuBUzPoT3QegJu1FYVd6ea0NyzNPKHz8Dx4suHsCgYEA8H6zEYj9LoZjcXTPBnXkh7i4L+fYGyRNKRy5/25IMM/PQFvOx86zy4CrpmVqOuzIipRQ8ajvi8oEATSQfUv2Vq4KDhR7Ma5e/B+j0fpOqOzyC8JlNGraTlt1H+0Z2oK4Wqa78wl/PBBgPl5whqo+aizSp01ISRTpkf9fMd4lqiMCgYEAm1bXKYIyjIpHt7IDZvlu+NlwLaHJnZj4QCH4JFCnQTYyE5rrB0ZBCsMunb8PyXLS66rsUSc1b0iKoPe4c2hRnKIHuY0wjXLci43DWahopnPW0+U8yXjtZNzVD+N2qr7wiSd1ftcyzvFehTImr7L/d2mqVailqex/ebH2Zy4fJJsCgYBGoA6aQa6IcTLW6MS7UvfP27FLcDyIi/qU7ji5CHvuybhFYDCzgMJQKvM9pF6z+0FRYQj8Tu46ERNn/zXHdf1bqzSMufsLtfdy9x+21FkxRpQ2W9o4sIB3rmgQ/iIvh1/4Tyq2zr0suGprJnTJN0mpCIy8UPe/boLBO0uLa3+IHQKBgQDeujbv9IVXAOJXdS7Zz5qfDveEJ/znPCkbxNn7iR1JREvSTbvGeaSun0YVKdDQ28cTKoh8RUd8xgpbYwCkfhSxQTa/TnxocRBkDdOF+8QKwh6RFYq7OdsZ5MjL19IZ+U5u/+0LCuXj1B56zCI2WRJmZl4um7ku5hbuZvPk9qZ5Pw==\n' +
            '-----END PRIVATE KEY-----\n'
        this._qz.security.setSignaturePromise(function (toSign) {
            return function (resolve, reject) {
                try {
                    var pk = r.KEYUTIL.getKey(privateKey)
                    var sig = new r.KJUR.crypto.Signature({'alg': 'SHA1withRSA'})
                    sig.init(pk)
                    sig.updateString(toSign)
                    var hex = sig.sign()
                    console.log('DEBUG: \n\n' + r.stob64(r.hextorstr(hex)))
                    resolve(r.stob64(r.hextorstr(hex)))
                } catch (err) {
                    console.error(err)
                    reject(err)
                }
            }
        })
    }

    launch() {
        return new Promise((resolve, reject) => {
            if (!this._qz.websocket.isActive()) {
                window.location.assign('qz:launch')
                //Retry 5 times, pausing 1 second between each attempt
                this.startConnection({retries: 5, delay: 1}).then((data) => {
                    resolve(data)
                })
            } else {
                resolve(1)
            }
        })
    }

    startConnection(config) {
        return new Promise((resolve, reject) => {
            if (!this._qz.websocket.isActive()) {
                // updateState('Waiting', 'default');
                // console.log('config: ', config)
                this._qz.websocket.connect(config).then(() => {
                    resolve('ok')
                }).catch()
            } else {
                console.log('An active connection with QZ already exists.', 'alert-warning')
                resolve('ok')
            }
        })
    }

    findPrinters() {
        return new Promise((resolve, reject) => {
            this._qz.printers.find().then(resp => {
                resolve(resp)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    printPDF(printer, pdf) {
        var config = this._qz.configs.create(printer)
        var data = [{
            type: 'raw',
            format: 'base64',
            data: pdf
        }
        ]
        this._qz.print(config, data).catch(function (e) {
            console.error(e)
        })
    }

    printTicketVenta(sale) {
        let printer = localStorage.getItem('impresora_tickets')
        if (!printer) {
            console.log('no impresoras')
            return
        }
        var config = this._qz.configs.create(printer)

        /*
        let items = []
        sale.cart.forEach(el => {
            let quantity = el.quantity.toString().padStart(3, '0')
            let name = el.item.name
            let subtotal = `S/. ${parseFloat(el.subtotal).toFixed(2)}`.padStart(30, ' ')
            items.push(`${quantity} ${name} \n  ${subtotal}\n`)
        })
        let total = parseFloat(sale.total).toFixed(2)
        let igv = parseFloat(sale.igv).toFixed(2)
        let subtotal = parseFloat(sale.subtotal).toFixed(2)
        let customer = sale.customer ? sale.customer.name : 'Publico General';
        */
       let itemsProducts = ``;
        //let items = [];
        sale.detail.forEach(el => {
            let quantity = el.quantity.toString().padStart(3, '0')
            let name = el.description
            let value_subtotal = parseFloat(el.subtotal);
            let value_discount = parseFloat(el.discount_value);
            let subtotal_item = value_subtotal - value_discount;
            let subtotal = `S/. ${parseFloat(subtotal_item).toFixed(2)}`.padStart(30, ' ')
            
            itemsProducts += `<tr>
                <td style="text-align: left;"><b>[${quantity}]</b> ${name}</td>
                <td style="text-align: right;">${parseFloat(el.price).toFixed(2)}</td>
                <td style="text-align: right;">${subtotal}</td>
            </tr>`;
        })

       let date_delivery = '';
       if (sale.date_delivery) {
            date_delivery = `<span><b>FECHA DE ENTREGA: </b>${Vue.prototype.$moment(sale.date_delivery).format('DD/MM/YYYY')}</span><br>`; 
       }

       let label_total_payment = '';
       let total_payment = '';
       if (sale.total_payment) {
        label_total_payment = `<span><b>A CUENTA</b></span><br>`;
        total_payment = `<span><b>S/ ${parseFloat(sale.total_payment).toFixed(2)}</b></span><br>`;
       }

       let label_total_pending = '';
       let total_pending = '';
       if (sale.total_pending) {
        label_total_pending = `<span><b>SALDO</b></span><br>`;
        total_pending = `<span><b>S/ ${parseFloat(sale.total_pending).toFixed(2)}</b></span><br>`;
       }

        let content = `<html>
        <head>
          <style type="text/css" media="screen, print">
            @import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;0,700;1,400;1,700&display=swap');
        
            @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
            
            body { font-family: 'Open Sans', sans-serif; }
          </style>
        </head>
        <body style="font-size: 11px;margin-left: 0px;margin-right: 0px;">
        <div style="text-align: center">
            <span><b>${sale.company_name}</b></span><br>
            <span>Direccion: ${sale.company_address}</span><br>
            <span><b>RUC: ${sale.company_ruc}</b></span>
            <br>
            <br>
            <span><b>${sale.sales_document} DE VENTA</b></span><br>
            <span><b>${sale.serie} - ${sale.correlative}</b></span><br><br>
        </div>
        <div>
            <span><b>ADQUIRIENTE</b></span><br>
            <span><b>${sale.customer_type_document}: </b>${sale.customer_document}</span><br>
            <span>${sale.customer_name}</span><br>
            <span><b>FECHA EMISION: </b>${Vue.prototype.$moment(sale.sales_date).format('DD/MM/YYYY HH:mm:ss a')}</span><br>
            ${date_delivery}
            <span><b>MONEDA: </b>SOLES PERUANOS</span><br>
            <span><b>IGV: </b>18.00</span>
        </div>
        <div>
            <table style="width: 100%; border-top: 2px solid; border-bottom: 2px solid;font-size: 11px;">
                <thead>
                    <tr>
                        <th style="text-align: left; width: 50%;">[CANT] DESCRIPCION</th>
                        <th style="text-align: right;width: 25%;">P/U</th>
                        <th style="text-align: right;width: 25%;">TOTAL</th>
                    </tr>
                </thead>
                <tbody>
                    ${itemsProducts}
                </tbody>
            </table>
        </div>
        <div>
            <table style="width: 100%;font-size: 11px;">
                <tbody>
                    <tr>
                        <td style="width: 20%"></td>
                        <td style="width: 40%; text-align: left;">
                            <span><b>DESCUENTO</b></span><br>
                            <span><b>SUBTOTAL</b></span><br>
                            <span><b>IGV</b></span><br>
                            <span><b>TOTAL</b></span><br>
                            ${label_total_payment}
                            ${label_total_pending}
                        </td>
                        <td style="width: 40%; text-align: right;">
                            <span><b>S/ ${parseFloat(sale.discount).toFixed(2)}</b></span><br>
                            <span><b>S/ ${parseFloat(sale.subtotal).toFixed(2)}</b></span><br>
                            <span><b>S/ ${parseFloat(sale.igv).toFixed(2)}</b></span><br>
                            <span><b>S/ ${parseFloat(sale.total).toFixed(2)}</b></span><br>
                            ${total_payment}
                            ${total_pending}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br>
        <br>
        <br>
        </body>
        </html>`;

        let data = [
            {
                type: 'pixel',
                format: 'html',
                flavor: 'plain', // or 'plain' if the data is raw HTML
                data: content
            }
        ]
        this._qz.print(config, data).catch(function (e) {
            console.error(e)
        })
    }
}

// this._qz.websocket.connect().then(function () {
//     console.log("Conectado")
//     console.log(qz.printers.find())
// });
// var print = new Index() // ReferenceError
export default Index
